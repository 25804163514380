<template>
  <div class="Home">
    <page-header/>

    <section class="uk-section uk-padding-remove" style="min-height: 820px;">
      <div class="uk-container uk-margin-large-bottom" uk-gird>
        <div class="qList">
          <div class="List_t uk-margin-bottom">
            <h3>{{ $t("orgaExam.register.eReg") }}</h3>
          </div>
          <div>
            <table class="uk-table uk-table-divider uk-table-responsive">
              <colgroup>
                <col width="10%">
                <col width="25%">
                <col width="13%">
                <col width="30%">
              </colgroup>
              <thead>
              <tr class="firstTr">
                <td>
                  {{ $t("orgaExam.register.eName") }} <span class="uk-text-danger">*</span>
                </td>
                <td>
                  <input
                      type="text"
                      :placeholder="$t('examName')"
                      class="uk-input"
                      v-model="exam.examName"
                  />
                </td>
                <td>
                  {{ $t("orgaExam.register.eLifeTime") }} <span class="uk-text-danger">*</span>
                </td>
                <td>
                  <date-picker
                      v-model="exam.startDate"
                      type="datetime"
                      :placeholder="$t('startDate')"
                      :lang="lang"
                      class="date-picker"
                      @change="realexamtaking()">
                  </date-picker>
                  <date-picker
                      class="date-picker"
                      type="datetime"
                      v-model="exam.endDate"
                      style="margin-left: 20px;"
                      :lang="lang"
                      :placeholder="$t('endDate')"
                      @change="realexamtaking()">
                  </date-picker>
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t("stuNav.exam") }} <span class="uk-text-danger">*</span>
                </td>
                <td>
                  <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid uk-flex uk-flex-center">
                    <span>
                      <input class="uk-radio" type="radio" name="realExam" :value="0" v-model="exam.realExam"
                             @click="testExamTaking()"/> {{ $t("textExam") }}
                    </span>
                    <!--                    <p  v-html="$t('orgaExam.register.wAgHide')"></p>-->
                    <span v-show="exam.startDate != '' && exam.endDate != ''">
                      <input class="uk-radio" type="radio" name="realExam" :value="1" v-model="exam.realExam"
                             @click="realexamtaking()"/> {{ $t("realexam") }}
                    </span>
                    <!--                    <p  v-html="$t('orgaExam.register.disAgHide')"></p>-->
                  </div>
                </td>
                <td>
                  {{ $t("totalNumberExaminee") }} <span class="uk-text-danger">*</span>
                </td>
                <td>
                  <input
                      type="text"
                      placeholder="0-10000"
                      class="uk-input"
                      maxlength="5"
                      min="1"
                      max="10000"
                      @keypress="onlyNumber"
                      style="width:62px"
                      v-if="exam.tms != '1'"
                      v-model="exam.examineeCount"
                  />
                  <span v-else> {{ exam.examineeCount }} </span>
                  <span class="uk-margin-left">{{ $t("orgaExam.register.person") }}</span>
                </td>
              </tr>
              </thead>
            </table>
            <div>
              <span>
                {{ $t("examRegister.fillText") }}
              </span>
            </div>
            <table class="uk-table uk-table-divider uk-table-responsive">
              <colgroup>
                <col width="10%">
                <col width="18%">
                <col width="10%">
                <col width="15%">
                <col width="10%">
                <col width="15%">
              </colgroup>
              <tbody>
              <tr class="firstTr">
                <td>
                  {{ $t("examRegister.examTime") }}
                </td>
                <td>
                  <input type="text" @keypress="onlyNumber" placeholder="0-999"
                         v-if="exam.realExam == 0"
                         class="uk-input" style="width:150px" maxlength="3" v-model="exam.examTime"/>
                  <input type="text" @keypress="onlyNumber" placeholder="0-999"
                         v-else disabled
                         class="uk-input" style="width:150px" maxlength="3" v-model="exam.examTime"/>
                  <span>{{ $t("orgaExam.register.minute") }}</span>
                </td>
                <td>
                  <span>UBL MEET
                  </span>
                </td>
                <td>
                  <div class="uk-flex uk-flex-left">
                    <span style="padding-top:7px">
                      <input class="uk-radio" type="radio" name="meet" :value="1"
                             v-model="exam.meet" @change="recordOff()"/>
                        {{ $t("examRegister.show") }}
                    </span>
                    <span style="padding-top:7px" class="uk-margin-left">
                        <input class="uk-radio" type="radio" name="meet" :value="0"
                               v-model="exam.meet"/>
                        {{ $t("examRegister.notShow") }}
                    </span>
                  </div>
                </td>
                <td>
                  {{ $t("examRegister.recordApp") }}
                </td>
                <td>
                  <div class="uk-flex uk-flex-left">
                    <span style="padding-top:7px">
                      <input class="uk-radio" type="radio" name="radioRecording" :value="true"
                             v-model="exam.recordingUse" @change="meetOff()"/>
                      {{ $t("examRegister.use") }}
                    </span>
                    <span style="padding-top:7px" class="uk-margin-left">
                        <input class="uk-radio" type="radio" name="radioRecording" :value="false"
                               v-model="exam.recordingUse"/>
                      {{ $t("examRegister.notUse") }}
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t("examRegister.examType") }}
                </td>
                <td>
                  <div class="uk-margin uk-flex uk-flex-middle">
                    <span>
                      <input class="uk-radio" type="radio" name="radio2"
                             v-model="exam.examType" value="public"/> {{ $t("orgaExam.register.public") }}
                    </span>
                    <p class="p-btn-hidding-bottom" v-html='$t("orgaExam.register.pubHide")'></p>
                    <span>
                      <input class="uk-radio uk-margin-left" type="radio" name="radio2"
                             v-model="exam.examType" value="private"/> {{ $t("orgaExam.register.pri") }}
                    </span>
                    <p class="p-btn-hidding-bottom" v-html='$t("orgaExam.register.priHide")'></p>
                  </div>
                </td>
                <td>
                  {{ $t("examRegister.resultSeeType") }}
                </td>
                <td>
                  <div class="uk-margin uk-grid-small uk-child-width-auto">
                    <label class="switch">
                      <input type="checkbox" checked v-if="exam.resultSeeType == 'true'" @click="examineeResultSee()">
                      <input type="checkbox" v-else @click="examineeResultSee()">
                      <span class="slider round"></span>
                    </label>
                  </div>
                </td>
                <td>
                  {{ $t("examRegister.survey") }}
                </td>
                <td>
                  <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid uk-grid uk-flex ">
                    <span>
                      <input class="uk-radio" type="radio" name="radioSurvey" :value="1" v-model="exam.surveyAttach"
                      /> {{ $t("orgaExam.register.attach") }}
                    </span>
                    <span>
                      <input class="uk-radio" type="radio" name="radioSurvey" :value="0" v-model="exam.surveyAttach"
                      /> {{ $t("orgaExam.register.unAttach") }}
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t("examRegister.description") }}
                </td>
                <td>
                    <textarea class="uk-textarea" rows="2"
                              :placeholder="$t('examdescription')"
                              v-model="exam.description"></textarea>
                </td>
                <td>
                  {{ $t("examRegister.scoreSeeType") }}
                </td>
                <td>
                  <div class="uk-margin uk-grid-small uk-child-width-auto">
                    <label class="switch">
                      <input type="checkbox" checked v-if="exam.scoreSeeType == 'true'" @click="examineeScoreSee()">
                      <input type="checkbox" v-else @click="examineeScoreSee()">
                      <span class="slider round"></span>
                    </label>
                  </div>
                </td>
                <!--                <td>{{$t("faceLogin")}}</td>-->
                <!--                <td>-->
                <!--                  <div class="uk-flex uk-flex-left">-->
                <!--                    <span style="padding-top:7px" v-if="exam.realExam == 0">-->
                <!--                      <input class="uk-radio" disabled name="faceLogin" type="radio"-->
                <!--                             v-model="exam.faceLogin"/>-->
                <!--                      {{$t("examRegister.use")}}-->
                <!--                    </span>-->
                <!--                    <span style="padding-top:7px" v-else>-->
                <!--                      <input :value="1" class="uk-radio" name="faceLogin" type="radio"-->
                <!--                             v-model="exam.faceLogin"/>-->
                <!--                      {{$t("examRegister.use")}}-->
                <!--                    </span>-->
                <!--                    <span class="uk-margin-left" style="padding-top:7px">-->
                <!--                        <input :value="0" class="uk-radio" name="faceLogin" type="radio"-->
                <!--                               v-model="exam.faceLogin"/>-->
                <!--                      {{$t("examRegister.notUse")}}-->
                <!--                    </span>-->
                <!--                  </div>-->
                <!--                </td>-->
                <td>
                  <span>Whale browser</span>
                </td>
                <td>
                  <div class="uk-flex uk-flex-left">
                    <span style="padding-top:7px">
                      <input class="uk-radio" type="radio" name="whaleUse" :value="1"
                             v-model="exam.whale"/>
                      {{ $t("examRegister.use") }}
                    </span>
                    <span style="padding-top:7px" class="uk-margin-left">
                        <input class="uk-radio" type="radio" name="whaleUse" :value="0"
                               v-model="exam.whale"/>
                      {{ $t("examRegister.notUse") }}
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t("examRegister.aiData") }}
                </td>
                <td>
                  <div class="uk-flex uk-flex-left">
                      <span>
                       <input class="uk-radio" type="radio" name="radioAIdata" :value="true"
                              v-model="exam.aiDataUse"/>
                        {{ $t("aiUse") }}
                     </span><br>
                    <span class="uk-margin-left">
                    <input class="uk-radio" type="radio" name="radioAIdata" :value="false"
                           v-model="exam.aiDataUse" @click="disAlertShow()"/>
                    {{ $t("notaiUse") }}
                  </span>
                  </div>
                </td>
                <td>
                  {{ $t("examRegister.questionShuffle") }}
                </td>
                <td>
                  <div class="uk-margin uk-grid-small uk-child-width-auto">
                    <label class="switch">
                      <input @click="examineeShuffle()" checked type="checkbox" v-if="exam.shuffle == 'true'">
                      <input @click="examineeShuffle()" type="checkbox" v-else>
                      <span class="slider round"></span>
                    </label>
                  </div>
                </td>
                <td>
                  <span v-html="$t('examRegister.monitoring')">
                  </span>
                </td>
                <td>
                  <div class="uk-flex uk-flex-left">
                    <span style="padding-top:7px" v-if="exam.aiDataUse == false">
                      <input class="uk-radio" type="radio" name="aialert" :value="1" disabled
                             v-model="exam.recordingUse"/>
                        {{ $t("examRegister.show") }}
                    </span>
                    <span style="padding-top:7px" v-else>
                      <input class="uk-radio" type="radio" name="aialert" :value="1"
                             v-model="exam.aialert"/>
                        {{ $t("examRegister.show") }}
                    </span>
                    <span style="padding-top:7px" class="uk-margin-left">
                        <input class="uk-radio" type="radio" name="aialert" :value="0"
                               v-model="exam.aialert"/>
                        {{ $t("examRegister.notShow") }}
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t("examRegister.memo") }}
                </td>
                <td>
                  <div class="uk-margin uk-grid-small uk-child-width-auto">
                    <label class="switch">
                      <input @click="mUse()" checked type="checkbox" v-if="exam.memoUse == 1">
                      <input @click="mUse()" type="checkbox" v-else>
                      <span class="slider round"></span>
                    </label>
                  </div>
                </td>
                <td>
                  {{ $t("examRegister.check") }}
                </td>
                <td>
                  <div class="uk-margin uk-grid-small uk-child-width-auto">
                    <label class="switch">
                      <input @click="cUse()" checked type="checkbox" v-if="exam.checkUse == 1">
                      <input @click="cUse()" type="checkbox" v-else>
                      <span class="slider round"></span>
                    </label>
                  </div>
                </td>
                <td>
                  Paint Use
                </td>
                <td>
                  <div class="uk-margin uk-grid-small uk-child-width-auto">
                    <label class="switch">
                      <input @click="pUse()" checked type="checkbox" v-if="exam.pntUse == 1">
                      <input @click="pUse()" type="checkbox" v-else>
                      <span class="slider round"></span>
                    </label>
                  </div>
                </td>

              </tr>
              <tr>
                <td>
                  {{ $t("examRegister.audio") }}
                </td>
                <td>
                  <div class="uk-margin uk-grid-small uk-child-width-auto">
                    <label class="switch">
                      <input @click="audioUse()" checked type="checkbox" v-if="exam.eType == 1">
                      <input @click="audioUse()" type="checkbox" v-else>
                      <span class="slider round"></span>
                    </label>
                  </div>
                </td>
                <td>
                  {{ $t("examRegister.id") }}
                </td>
                <td>
                  <div class="uk-margin uk-grid-small uk-child-width-auto">
                    <label class="switch">
                      <input @click="cardsCheck()" checked type="checkbox" v-if="exam.cardCheck == 1">
                      <input @click="cardsCheck()" type="checkbox" v-else>
                      <span class="slider round"></span>
                    </label>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="uk-width-1-1 uk-flex uk-flex-right">
          <router-link tag="a" :to="{name: 'OrganizationExamList'}"
                       style="background: #fff ; color: rgb(1, 176, 255); border-color: rgb(1, 176, 255)"
                       class="uk-button uk-button-primary qbtn_d uk-width-1-2@m uk-margin-small-right">
            {{ $t("orgaExam.examResultView.eList") }}
          </router-link>
          <button type="button" class="qbtn_d uk-button uk-width-1-2@m"
                  v-if="this.$route.params.examId == 0"
                  style="background: rgb(1, 176, 255)"
                  @click="registerExam()">{{ $t("orgaExam.register.reg") }}
          </button>
          <button type="button" class="qbtn_d uk-button uk-width-1-2@m" v-else
                  @click="updateExam()">{{ $t("orgaExam.register.modify") }}
          </button>
        </div>
      </div>
    </section>
    <page-footer/>
  </div>
</template>
<script>
import moment from 'moment'
import ExamService from "../../services/ExamService"
import {mapGetters} from "vuex"
import DatePicker from "vue2-datepicker"
import 'vue2-datepicker/index.css';
import PageHeader from '@/components/Organizcmp/OrganizHeader.vue'

const PageFooter = () => import("../Organizcmp/OrganizFooter.vue");

// Vue.use(MultiSelectPlugin);

export default {
  name: "app",
  components: {
    DatePicker,
    PageHeader,
    PageFooter
  },
  computed: {
    ...mapGetters(["isLoggedIn", "currentUser"]),
  },
  filters: {
    moment: function (date) {
      return moment(date).format('YYYY-MM-DD, h:mm:ss')
    }
  },
  data() {
    return {
      examId: 0,
      exam: {
        examName: '',
        description: '',
        startDate: '',
        endDate: '',
        examType: 'private',
        examPrice: '',
        countryId: [],
        examTime: '',
        resultSeeType: 'false',
        scoreSeeType: 'false',
        reTakeExam: 'no',
        shuffle: 'false',
        examStatusControl: 'false',
        examineeCount: '',
        surveyAttach: 0,
        realExam: 0,
        meet: 0,
        aiDataUse: false,
        recordingUse: false,
        whale: 0,
        faceLogin: 0,
        tms: '0',
        aialert: 0,
        memoUse: 1,
        checkUse: 1,
        pntUse: 0,
        eType: 0,
        cardCheck: 0
      },
      examCodeUsedCount: 0,
      lang: {
        days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        months: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec"
        ],
        placeholder: {
          dateRange: "Start date ~ End date"
        }
      },
      localFields: {value: 'id', text: 'name'}
    };
  },
  created() {
  },
  mounted() {
    if (this.$route.params.examId) {
      if (this.$route.params.examId > 0) {
        this.getExamDetails(this.$route.params.examId)
      }
    }
  },
  methods: {
    recordOff() {
      this.exam.recordingUse = false
    },
    meetOff() {
      this.exam.meet = 0
    },
    disAlertShow() {
      this.exam.aialert = 0
    },
    testExamTaking() {
      this.exam.aiDataUse = false;
      this.exam.recordingUse = false;
      this.exam.faceLogin = 0
      this.exam.aialert = 0
    },
    realexamtaking() {
      var currentDate = new Date(this.exam.startDate).getTime();
      var eendDate = new Date(this.exam.endDate).getTime();

      if (currentDate > 0 && eendDate > 0) {
        if (currentDate < eendDate) {
          var millSec = eendDate - currentDate;
          this.exam.examTime = (millSec / 60000).toFixed(0);
          this.exam.reTakeExam = 'no'
        } else {
          alert("please check start date or end date!")
          return
        }
      }
    },
    async getExamDetails(eId) {
      try {
        const response = await ExamService.getExamDetails(eId, {
          grpId: 0,
          orgId: this.currentUser.organizs[0].organizId
        });
        let examDetail = response.data.result.examDetail;
        this.examCodeUsedCount = examDetail.examCodeUsedCount;
        this.exam.examName = examDetail.examName;
        this.exam.description = examDetail.description;
        let daa = new Date(examDetail.examSdate)
        let dee = new Date(examDetail.examEdate)
        this.exam.startDate = daa;
        this.exam.endDate = dee;
        this.exam.examTime = examDetail.examTime;
        this.exam.examType = examDetail.examType;
        this.exam.examPrice = examDetail.examPrice;
        this.exam.examineeCount = examDetail.ecodeCount;
        this.exam.resultSeeType = examDetail.resultSeeType;
        this.exam.scoreSeeType = examDetail.scoreSee;
        this.exam.examStatusControl = examDetail.statusControl;
        this.exam.countryId = examDetail.countryId;
        this.exam.surveyAttach = examDetail.survey;
        this.exam.reTakeExam = examDetail.reTake;
        this.exam.realExam = examDetail.realExam;
        this.exam.aiDataUse = examDetail.aiUse;
        this.exam.recordingUse = examDetail.recording;
        this.exam.checkUse = examDetail.checkUse
        this.exam.memoUse = examDetail.memoUse
        this.exam.eType = examDetail.eType
        this.exam.pntUse = examDetail.pntUse
        this.exam.whale = examDetail.whale;
        this.exam.aialert = examDetail.aialert;
        this.exam.meet = examDetail.meet;
        this.exam.faceLogin = examDetail.faceLogin;
        this.exam.shuffle = examDetail.shuffle;
        this.exam.cardCheck = examDetail.cardCheck;
        this.exam.tms = examDetail.isTms
      } catch (error) {
        console.log(error)
      }
    },
    async updateExam() {
      try {
        if (this.examCodeUsedCount > this.exam.examineeCount) {
          alert("You should increase examinee number because " + this.examCodeUsedCount +
              " member using it!!")
        } else {

          if (this.exam.examName == '') {
            alert("please write exam name");
            return;
          }
          if (this.exam.examTime == '') {
            alert("please write exam time");
            return;
          }
          if (this.exam.examineeCount == '') {
            alert("please write examinee ");
            return;
          }
          if (this.exam.examStatusControl == '') {
            alert("please choose Exam control");
            return;
          }
          if (this.exam.startDate == '') {
            alert("please choose exam start date");
            return;
          }
          if (this.exam.endDate == '') {
            alert("please choose exam end date");
            return;
          }
          if (this.exam.examType == '') {
            alert("please choose exam type");
            return;
          }
          if (this.exam.surveyAttach != 0 && this.exam.surveyAttach != 1) {
            alert("please choose exam survey type");
            return;
          }
          if (this.exam.faceLogin != 0 && this.exam.faceLogin != 1) {
            alert("please choose exam face login")
            return
          }
          if (this.exam.reTakeExam == '') {
            alert("please choose exam reTake")
          }

          var currentDate = new Date(this.exam.startDate).getTime();
          var eendDate = new Date(this.exam.endDate).getTime();

          if (currentDate > eendDate) {
            alert("Please check your exam date & time.")
            return
          }

          let item = {
            id: this.$route.params.examId,
            examName: this.exam.examName,
            description: this.exam.description,
            examTime: this.exam.examTime,
            examStatusControl: this.exam.examStatusControl,
            examPrice: this.exam.examPrice,
            startDate: this.exam.startDate,
            endDate: this.exam.endDate,
            examType: this.exam.examType,
            resultSeeType: this.exam.resultSeeType,
            scoreSee: this.exam.scoreSeeType,
            examineeCount: this.exam.examineeCount,
            examSites: this.exam.countryId,
            reTake: this.exam.reTakeExam,
            survey: this.exam.surveyAttach,
            realExam: this.exam.realExam,
            aiuse: this.exam.aiDataUse,
            recording: this.exam.recordingUse,
            whale: this.exam.whale,
            aialert: this.exam.aialert,
            meet: this.exam.meet,
            faceLogin: this.exam.faceLogin,
            shuffle: this.exam.shuffle,
            cardCheck: this.exam.cardCheck,
            memoUse: this.exam.memoUse,
            checkUse: this.exam.checkUse,
            pntUse: this.exam.pntUse,
            eType: this.exam.eType
          };
          const response = await ExamService.updateExam(item);
          if (response.data.status == 200) {
            alert("Success");
            window.location.href = "/" + this.$i18n.locale + "/organization/Organization/ExamList"
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    examineeResultSee() {
      if (this.exam.resultSeeType == 'true') {
        this.exam.resultSeeType = 'false'
      } else {
        this.exam.resultSeeType = 'true'
      }
    },
    examineeScoreSee() {
      if (this.exam.scoreSeeType == 'true') {
        this.exam.scoreSeeType = 'false'
      } else {
        this.exam.scoreSeeType = 'true'
      }
    },
    examineeShuffle() {
      if (this.exam.shuffle == 'true') {
        this.exam.shuffle = 'false'
      } else {
        this.exam.shuffle = 'true'
      }
    },
    pUse() {
      if (this.exam.pntUse == 1) {
        this.exam.pntUse = 0
      } else {
        this.exam.pntUse = 1
      }
    },
    mUse() {
      if (this.exam.memoUse == 1) {
        this.exam.memoUse = 0
      } else {
        this.exam.memoUse = 1
      }
    },
    cardsCheck() {
      if (this.exam.cardCheck == 1) {
        this.exam.cardCheck = 0
      } else {
        this.exam.cardCheck = 1
      }
    },
    audioUse() {
      if (this.exam.eType == 1) {
        this.exam.eType = 0
      } else {
        this.exam.eType = 1
      }
    },
    cUse() {
      if (this.exam.checkUse == 1) {
        this.exam.checkUse = 0
      } else {
        this.exam.checkUse = 1
      }
    },
    async registerExam() {
      try {
        if (this.exam.examName == '') {
          alert("please write exam name");
          return;
        }
        if (this.exam.examTime == '') {
          alert("please write exam time");
          return;
        }
        if (this.exam.examineeCount == '') {
          alert("please write examinee ");
          return;
        }
        if (this.exam.examStatusControl == '') {
          alert("please choose Exam control");
          return;
        }
        if (this.exam.startDate == '') {
          alert("please choose exam start date");
          return;
        }
        if (this.exam.endDate == '') {
          alert("please choose exam end date");
          return;
        }
        if (this.exam.examType == '') {
          alert("please choose exam type");
          return;
        }
        if (this.exam.surveyAttach != 0 && this.exam.surveyAttach != 1) {
          alert("please choose exam survey type");
          return;
        }
        if (this.exam.faceLogin != 0 && this.exam.faceLogin != 1) {
          alert("please choose exam face login")
          return
        }
        if (this.exam.reTakeExam == '') {
          alert("please choose exam reTake")
          return
        }

        var currentDate = new Date(this.exam.startDate).getTime();
        var eendDate = new Date(this.exam.endDate).getTime();

        if (currentDate > eendDate) {
          alert("Please check your exam date & time.")
          return
        }

        let item = {
          examName: this.exam.examName,
          description: this.exam.description,
          examTime: this.exam.examTime,
          examStatus: this.exam.examStatus,
          examStatusControl: this.exam.examStatusControl,
          // examTotalScore: this.exam.examTotalScore ,
          examPrice: this.exam.examPrice,
          startDate: this.exam.startDate,
          endDate: this.exam.endDate,
          examType: this.exam.examType,
          resultSeeType: this.exam.resultSeeType,
          scoreSee: this.exam.scoreSeeType,
          examineeCount: this.exam.examineeCount,
          examSites: this.exam.countryId,
          reTake: this.exam.reTakeExam,
          survey: this.exam.surveyAttach,
          realExam: this.exam.realExam,
          aiuse: this.exam.aiDataUse,
          recording: this.exam.recordingUse,
          whale: this.exam.whale,
          aialert: this.exam.aialert,
          meet: this.exam.meet,
          faceLogin: this.exam.faceLogin,
          shuffle: this.exam.shuffle,
          memoUse: this.exam.memoUse,
          checkUse: this.exam.checkUse,
          pntUse: this.exam.pntUse,
          eType: this.exam.eType,
          cardCheck: this.exam.cardCheck
        };
        const response = await ExamService.createExam(item);
        if (response.data.status == 200) {
          if (response.data.success) {
            alert("Exam is registered successfully");

            window.location.href = "/" + this.$i18n.locale + "/organization/Organization/ExamList"
          }
        } else {
          alert(response.data.message)
        }
      } catch (error) {
        console.log(error);
      }
    },
    // async getcountries() {
    //   try {
    //     const response = await UserService.getCountries()
    //     this.countries = response.data
    //   } catch (error) {
    //     this.error = error.response.data.error
    //   }
    // },
    onlyNumber($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault();
      }
    }
  },

};
</script>
<style>
/*@import url(https://cdn.syncfusion.com/ej2/material.css);*/

.date-picker {
  width: 198px;
}

.uk-table td.subtit {
  border-right: 1px solid #dee2fe;
  text-align: center;
  font-weight: bold;
  color: #404348;
  padding: 20px 0px;
  font-size: 14px;
  background-color: #eeeeef;
}

.uk-table td.subtits {
  border-right: 1px solid #dee2fe;
  border-left: 1px solid #dee2fe;
  font-weight: bold;
  padding: 20px 0px;
  padding-left: 10px;
  color: #404348;
  font-size: 14px;
  background-color: #eeeeef;
}

.uk-table .firstTr {
  border-top: 3px solid #00abd8;
}

.uk-table td {
  border: 1px solid #dee2fe;
  font-size: 13px;
  background: #fff;
  padding: 8px 20px;
  vertical-align: middle;
  height: 46px;
}


.qList h3 {
  height: 30px;
  margin-right: 20px;
  padding-top: 10px;
  font-size: 1.3em;
  font-weight: 900;
  color: rgb(1, 176, 255);
  padding-left: 15px;
  border-left: 2px solid rgb(1, 176, 255);
}

.deleteButton :hover {
  color: black;
}

.editButton :hover {
  color: black
}

.qList h4 {
  height: 30px;
  margin-right: 20px;
  padding-top: 10px;
  font-size: 1.3em;
  font-weight: 900;
  color: rgb(1, 176, 255);
  padding-left: 15px;
}

/* button */
.qbtn_d {
  width: 120px;
  background-color: #032d67;
  border: 1px solid #fff;
  display: inline-block;
  padding: 0px 10px;
  color: #fff;
  font-weight: 500;
  border-bottom-left-radius: 1em;
  border-top-right-radius: 1em;
  border-top-left-radius: 1em;
  border-bottom-right-radius: 1em;
  min-width: 30px;
  text-align: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* remove datepicker height */
.subtit .mx-datepicker .mx-input-wrapper .mx-input-append .mx-calendar-icon {
  height: 0% !important;
}

.date-picker >>> .mx-datepicker-popup .mx-calendar {
  height: 400px !important;
}

.date-picker >>> .mx-panel {
  height: 350px;
}

.p-btn-hidding-top {
  margin-bottom: 0px;
  padding-left: 10px;
  margin-top: -10px
}

.p-btn-hidding-bottom {
  margin-bottom: 0px;
  padding-left: 10px;
  margin-top: 0px;
}

.mx-table-date th {
  color: #2a90e9 !important;
}

.mx-table-date .today {
  color: #2a90e9 !important;
}

.date-picker >>> .mx-datepicker-popup .mx-table-date .today {
  color: #fff6ee !important;
}

thead tr th {
  background: white !important;
}

.mx-table-date .today {
  color: #edecee !important;
  background: #2a90e9 !important;
}
</style>
